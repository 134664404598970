import { graphql, PageProps } from 'gatsby'

import { WpPage } from '@_types/graphql-types'

import FlexPage from '@components/FlexPage'

import '@styles/gutenberg.scss'

type PageData = {
  singlePage: WpPage
}

function page({ data: { singlePage }, path }: PageProps<PageData>) {
  return <FlexPage data={singlePage} path={path} />
}

export default page

export const pageQuery = graphql`
  query GET_FLEXIBLE_PAGE($id: String!) {
    singlePage: wpPage(id: { eq: $id }) {
      ...FlexiblePageFields
    }
  }
`
